<!-- Mto particular de Grupos Funerarias -->

<template>
  <div class="jefeszona_M">
    <!-- Base Mto-->
    <base_M
      v-if="schema"
      :schema="schema"
      :titulo="titulo"
      :accion="accion"
      @onEvent="event_capture"
    >
      <!-- Slot con los controles del Mto -->
      <template v-slot:ctrls="{}">
        <v-container>
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.name.value"
            :label="schema.ctrls.name.label"
            :disabled="!edicion"
          >
          </v-text-field>
        </v-container>
      </template>
    </base_M>
  </div>
</template>

<script>
import { mixinMto } from "@/mixins/mixinMto.js";
import plugs from "@/common/general_plugs";
const base_M = () => plugs.groute("base_M.vue", "base");

export default {
  mixins: [mixinMto],
  components: { base_M },
  props: {
    id_accion: { type: Object, default: null },
    btra: { type: [Array, Object], default: undefined }
  },

  data() {
    return {
      mto: "gruP",
      titulo: "Mantenimiento de Grupos Funerarias",
      schema: null,
      id: this.id_accion.id,
      accion: this.id_accion.accion,
      edicion: false
    };
  },

  methods: {
    // gestor de eventos
    event_capture(evt) {
      console.log("*** event_capture grup_M. Accion: " + evt.accion + " ***");

      if (this.$isFunction(this[evt.accion])) {
        this[evt.accion](evt);
        return;
      }
      this.acciones_Mto(evt);
    }
  }
};
</script>
